import { Controller } from "@hotwired/stimulus"
import Cookies from "js-cookie"

export default class extends Controller {
  static values = {
    reducedMotion: Boolean,
    moreContrast: Boolean,
    // inverted: { type: Boolean, default: false },
    inverted: Boolean,
  }

  connect() {
    if (this.#hasLocalStorageItem("reducedMotion")) {
      this.reducedMotionValue = this.#getLocalStorageItem("reducedMotion")
    } else {
      this.reducedMotionValue = this.#prefersReducedMotion
    }

    if (this.#hasLocalStorageItem("moreContrast")) {
      this.moreContrastValue = this.#getLocalStorageItem("moreContrast")
    } else {
      this.moreContrastValue = this.#prefersMoreContrast
    }
  }

  toggleInverted(event) {
    this.invertedValue = event.detail.checked
  }

  invertedValueChanged() {
    Cookies.set("inverted", this.invertedValue, {
      expires: 365,
    })
  }

  // toggleMoreContrast() {
  //   this.#localStorage = { key: "moreContrast", value: !this.moreContrastValue }
  //   this.moreContrastValue = !this.moreContrastValue
  // }

  // toggleReducedMotion() {
  //   this.#localStorage = {
  //     key: "reducedMotion",
  //     value: !this.reducedMotionValue,
  //   }
  //   this.reducedMotionValue = !this.reducedMotionValue
  // }

  #hasLocalStorageItem(key) {
    return this.#getLocalStorageItem(key) !== null
  }

  #getLocalStorageItem(key) {
    return JSON.parse(localStorage.getItem(key))
  }

  set #localStorage(item) {
    localStorage.setItem(item.key, JSON.stringify(item.value))
  }

  get #prefersReducedMotion() {
    return window.matchMedia("(prefers-reduced-motion: reduce)").matches
  }

  get #prefersMoreContrast() {
    return window.matchMedia("(prefers-contrast: more)").matches
  }
}
