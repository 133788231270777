import { application } from "./application"
import { registerControllers } from "stimulus-vite-helpers"
import componentControllers from "../../components"
import blockComponentControllers from "../../components/blocks"
import { registerControllers as registerLayoutControllers } from "@asgerb/apparatus-layout"

const controllers = import.meta.glob("./**/*_controller.js", {
  eager: true,
})

registerControllers(application, controllers)
registerControllers(application, componentControllers)
registerControllers(application, blockComponentControllers)
registerLayoutControllers(application)
