import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["highlight", "overlayFrame", "description", "mapbox"]
  static values = { highlightText: String, color: String, sidebarOpen: Boolean }
  static classes = ["overlayOpen"]

  openDescription() {
    this.descriptionTarget.hidden = false
  }

  onMapLocationEnter(event) {
    this.colorValue = event.detail.properties.color
    this.highlightTextValue = event.detail.properties.name
  }

  onMapLocationLeave() {
    this.highlightTextValue = ""
  }

  onMapLocationClick(event) {
    this.overlayFrameTarget.src = `/locations/${event.detail.properties.id}`
    this.highlightTextValue = ""
    this.colorValue = event.detail.properties.color
  }

  onOverlayOpen() {
    this.element.classList.add(this.overlayOpenClass)
  }

  onOverlayClose() {
    this.element.classList.remove(this.overlayOpenClass)
  }

  highlightTextValueChanged() {}

  colorValueChanged() {
    if (!this.colorValue) return
    this.element.style.setProperty("--color", `var(--${this.colorValue})`)
  }

  flyToLocation(event) {
    if (this.mapboxComponentController) {
      this.mapboxComponentController.flyToLocation(event.params.locationId)
    }
  }

  get mapboxComponentController() {
    return this.application.getControllerForElementAndIdentifier(
      this.mapboxTarget,
      "mapbox-component",
    )
  }
}
