import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { checked: Boolean }
  static classes = ["checked"]

  onChange(event) {
    this.checkedValue = event.target.checked
    this.dispatch("change", { detail: { checked: this.checkedValue } })
  }

  checkedValueChanged() {
    this.element.classList.toggle(this.checkedClass, this.checkedValue)
  }
}
