import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.#dispatchEvent()
  }

  toggle() {
    this.hidden = !this.hidden
  }

  open() {
    this.hidden = false
  }

  close() {
    this.hidden = true
  }

  #dispatchEvent() {
    this.dispatch(this.hidden ? "close" : "open")
  }

  set hidden(hidden) {
    this.element.hidden = hidden
    this.#dispatchEvent()
  }

  get hidden() {
    return this.element.hidden
  }
}
