import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  className = "low_contrast"

  connect() {
    this.matchMedia = window.matchMedia(
      "(prefers-contrast: no-preference), (prefers-contrast: less)",
    )
    localStorage.setItem("low-contrast-mode", this.#isLowContrast)
    this.#toggleClass()
    this.matchMedia.addEventListener("change", () => {
      this.#toggleClass()
    })
  }

  disconnect() {}

  toggle() {
    this.#localStorage = !this.#isLowContrast
    this.#toggleClass()
  }

  #toggleClass() {
    document.documentElement.classList.toggle(
      this.className,
      this.#isLowContrast,
    )
  }

  get #hasLocalStorage() {
    return this.#isLowContrastLocalStorage !== null
  }

  get #isLowContrast() {
    if (this.#hasLocalStorage) return this.#isLowContrastLocalStorage
    return this.#isLowContrastMedia
  }

  get #isLowContrastMedia() {
    return this.matchMedia.matches
  }

  get #isLowContrastLocalStorage() {
    return JSON.parse(localStorage.getItem("low-contrast-mode"))
  }

  set #localStorage(value) {
    localStorage.setItem("low-contrast-mode", JSON.stringify(value))
  }
}
