import { Controller } from "@hotwired/stimulus"
import mapboxgl from "mapbox-gl"
import * as turf from "@turf/turf"
import "mapbox-gl/dist/mapbox-gl.css"

export default class extends Controller {
  static values = { accessToken: String, areas: Object, locations: Array }
  areaBlurSteps = [
    -0.25, -0.225, -0.2, -0.175, -0.15, -0.125, -0.1, -0.075, -0.05, -0.025, 0,
    0.025, 0.05, 0.075, 0.1, 0.125, 0.15, 0.175, 0.2, 0.225, 0.25,
  ]
  center = [12.584740917525355, 55.677580008837676]
  bounds = [
    [12.54766766013193, 55.70154886207277],
    [12.632419869874873, 55.65459187091451],
  ]
  maxBounds = [
    [11.839637147141472, 55.3141704962494],
    [13.055502322541258, 56.10617404920726],
  ]
  opacities = { red: 1, yellow: 1, green: 1 }
  minZoom = 10
  maxZoom = 14

  connect() {
    mapboxgl.accessToken = this.accessTokenValue

    this.map = new mapboxgl.Map({
      container: this.element,
      style: "mapbox://styles/asgerbehncke/client1i4005z01r0e2sq48fj",
      maxZoom: this.maxZoom,
      minZoom: this.minZoom,
      maxBounds: this.maxBounds,
      bounds: this.bounds,
    })

    this.map.dragRotate.disable()
    this.map.touchZoomRotate.disableRotation()
    this.map.keyboard.disable()
    this.map.boxZoom.disable()

    let collection = turf.featureCollection(this.areasValue.features)
    collection = turf.polygonSmooth(collection, { iterations: 4 })

    this.map.on("load", () => {
      this.map.addSource("areas", {
        type: "geojson",
        data: collection,
      })

      this.map.addSource("locations", {
        type: "geojson",
        data: { type: "FeatureCollection", features: this.locationsValue },
      })

      this.map.addLayer(
        {
          id: "areas",
          type: "fill",
          source: "areas",
          paint: {
            "fill-color": [
              "get",
              ["get", "accessibility"],
              ["literal", this.#colors],
            ],
            "fill-opacity": 0.2,
          },
        },
        "waterway",
      )

      this.map.addLayer({
        id: "locations",
        type: "circle",
        source: "locations",
        paint: {
          "circle-radius": [
            "interpolate",
            ["linear"],
            ["zoom"],
            // zoom is 5 (or less) -> circle radius will be 1px
            this.minZoom,
            12,
            12.5,
            24,
            // zoom is 15 (or greater) -> circle radius will be 5px
            this.maxZoom,
            36,
          ],
          "circle-color": [
            "get",
            ["get", "accessibility"],
            ["literal", this.#colors],
          ],
          "circle-stroke-width": 2.88,
          "circle-stroke-color": "black",
        },
      })

      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        maxWidth: "none",
      })

      this.map.on("mouseenter", "locations", (e) => {
        const feature = e.features[0]
        this.dispatch("locationEnter", { detail: feature })
        const coordinates = feature.geometry.coordinates.slice()
        this.map.getCanvas().style.cursor = "pointer"
        popup
          .setLngLat(coordinates)
          .setHTML(feature.properties.name)
          .addTo(this.map)
      })

      this.map.on("mouseleave", "locations", () => {
        this.dispatch("locationLeave")
        this.map.getCanvas().style.cursor = ""
        popup.remove()
      })

      this.map.on("click", "locations", (e) => {
        this.dispatch("locationClick", { detail: e.features[0] })
      })
    })
  }

  disconnect() {
    if (this.map) this.map.remove()
  }

  flyToLocation(id) {
    this.map.flyTo({
      center: this.locationsValue.find((l) => l.properties.id === id).geometry
        .coordinates,
      duration: 1000,
      essential: true,
    })
  }

  get #colors() {
    return {
      low: this.#bodyStyles.getPropertyValue("--accessibility-low"),
      medium: this.#bodyStyles.getPropertyValue("--accessibility-medium"),
      high: this.#bodyStyles.getPropertyValue("--accessibility-high"),
      none: this.#bodyStyles.getPropertyValue("--accessibility-none"),
    }
  }

  get #bodyStyles() {
    if (!this._bodyStyles) {
      this._bodyStyles = window.getComputedStyle(document.body)
    }
    return this._bodyStyles
  }
}
