import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["scrollable"]

  scrollTo(event) {
    event.preventDefault()
    const anchor = event.currentTarget.getAttribute("href")
    const element = document.querySelector(anchor)
    window.location.hash = anchor
    if (element) {
      element.nextElementSibling.scrollIntoView({
        behavior: "smooth",
        block: "start",
      })
    } else {
      console.warn("Missing element to scroll to")
      return
    }
  }

  scrollToTop(event) {
    event.preventDefault()
    this.#scrollable.scrollTo({ top: 0, behavior: "smooth" })
  }

  get #scrollable() {
    if (this.hasScrollableTarget) return this.scrollableTarget
    return document.documentElement
  }
}
