import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.observer = new IntersectionObserver(
      ([e]) => e.target.toggleAttribute("stuck", e.intersectionRatio < 1),
      { threshold: [1] },
    )
    this.observer.observe(this.element)
  }

  disconnect() {
    this.observer.disconnect()
  }
}
